.app .ready {
  color: #257b25;
}
.app .hot {
  color: #bf3131;
}
.app .timer {
  float: left;
  font-family: serif;
  margin-right: 5%;
  text-align: center;
  width: 45%;
}
.app .timer button {
  height: 50px;
  width: 100px;
}
.app .timer .time {
  font-size: 300%;
}
.app .timer .bigText {
  font-size: 500%;
}
.app .how-to {
  float: left;
  width: 45%;
}
/*# sourceMappingURL=index.03574b25.css.map */
