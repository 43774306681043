.app {

  .ready {
    color: #257b25;
  }

  .hot {
    color: #bf3131;
  }

  .timer {
    float: left;
    font-family: serif;
    margin-right: 5%;
    text-align: center;
    width: 45%;

    button {
      height: 50px;
      width: 100px;
    }

    .time {
      font-size: 300%;
    }

    .bigText {
      font-size: 500%;
    }
  }

  .how-to {
    float: left;
    width: 45%;
  }
}
